<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="投资人id" prop="userId" >
        <a-input v-model="form.userId" readOnly/>
      </a-form-model-item>
<!--      <a-form-model-item label="顾问ID" prop="mentorUserId" >-->
<!--        <a-input v-model="form.mentorUserId" placeholder="请输入顾问ID" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="分析师ID" prop="analystUserId" >-->
<!--        <a-input v-model="form.analystUserId" placeholder="请输入分析师ID" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="项目id" prop="projectId" >
        <a-input v-model="form.projectId" readOnly/>
      </a-form-model-item>
      <a-form-model-item label="上报金额(元)" prop="reportMoney" >
        <a-input readOnly v-model="form.reportMoney" />
      </a-form-model-item>
      <a-form-model-item label="投资金额(元)" prop="money" >
        <a-input v-model="form.money" placeholder="请输入投资金额" />
      </a-form-model-item>

      <a-form-model-item label="审核结果" prop="auditResults" >
        <a-select placeholder="请选择审核结果" v-model="form.auditState">
          <a-select-option :key="0" :value="0">请选择</a-select-option>
          <a-select-option :key="1" :value="1">通过</a-select-option>
          <a-select-option :key="2" :value="2">拒绝</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-show="form.auditState == 2" label="拒绝原因" prop="auditReason" >
        <a-input v-model="form.auditReason" placeholder="请输入拒绝原因" />
      </a-form-model-item>

<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" readOnly />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getSuccess, addSuccess, updateSuccess, auditSuccess} from '@/api/biz/success'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        mentorUserId: null,

        analystUserId: null,

        projectId: null,

        money: null,

        reportMoney: null,
        auditState: null,
        auditReason: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        mentorUserId: null,
        analystUserId: null,
        projectId: null,
        money: null,
        reportMoney: null,
        auditState: null,
        auditReason: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSuccess({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '审核'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.auditState === 1 && !this.form.money) {
            this.$message.error("请输入投资金额")
            return false;
          } else if (this.form.auditState !== 1 && this.form.auditState !== 2) {
            this.$message.error("请选择审核结果")
            return false;
          }

          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            auditSuccess(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
